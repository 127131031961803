.ToggleButtons {
  .base {
    width: 112px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .buttonGroup {
      display: flex;
    }
  }
}

.button {
  width: 56px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
}

.spinner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  opacity: 0.8;
  border-top-color: #999;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
