.line {
  border: 3px solid #fff;
}

.frameLeft {
  width: 0;
  border-left: 50px solid #fff;
  margin-right: 50px;
}

.frameRight {
  width: 0;
  border-right: 50px solid #fff;
  margin-left: 50px;
  background: white;
}
