.popup {
  animation: popup 200ms cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
  animation: popup 300ms cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
}

@keyframes popup {
  0% {
    transform: translateY(10px) scale(0.8);
    opacity: 0;
  }
  80% {
    transform: translateY(0) scale(1.01);
  }
  100% {
    transform: translateY(0) scale(1);
  }
  80%,
  100% {
    opacity: 1;
  }
}
